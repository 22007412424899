const BASE_URL = "https://core.andreis-vibes.ru";

// Утилита для работы с токеном в куках
const authTokenKey = "authToken";

const saveToken = (token) => {
  document.cookie = `${authTokenKey}=${token}; Path=/; Secure; SameSite=Strict`;
};

const getToken = () => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${authTokenKey}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : null;
};

const deleteToken = () => {
  document.cookie = `${authTokenKey}=; Path=/; Max-Age=-1`;
};


// API для входа
const LoginApi = async (username, password) => {
  try {
    const response = await fetch(`${BASE_URL}/account/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      throw new Error("Ошибка при входе");
    }

    const { token } = await response.json();
    if (token) {
      saveToken(token);
      console.log("Токен сохранен в куках");
      return { success: true };
    } else {
      throw new Error("Токен не был получен");
    }
  } catch (error) {
    console.error("Ошибка при входе:", error);
    return { success: false, errorMsg: error.message };
  }
};

// API для получения данных профиля
const fetchProfileData = async () => {
  try {
    const token = getToken(); 
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/account/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при получении данных профиля");
    }

    return await response.json();
  } catch (error) {
    console.error("Ошибка при загрузке данных профиля:", error);
    throw error;
  }
};

// API для выхода из аккаунта
const LogoutApi = async () => {
  try {
    const token = getToken();
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/account/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при выходе из аккаунта");
    }

    deleteToken();
    console.log("Выход выполнен успешно, токен удален из куков");
    return { success: true };
  } catch (error) {
    console.error("Ошибка при выходе из аккаунта:", error);
    throw error;
  }
};

const ForgotPasswordApi = async (data) => {
  try {
    const response = await fetch(`${BASE_URL}/account/forgot_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Ошибка при восстановлении пароля");
    }

    return { success: true };
  } catch (error) {
    console.error("Ошибка при восстановлении пароля:", error);
    return { success: false, errorMsg: error.message };
  }
};


export { LoginApi, fetchProfileData, LogoutApi, ForgotPasswordApi };
