import React from "react";
import styled from "styled-components";

const CopyNotification = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  font-size: 14px;
`;

const CloseNotificationButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  font-weight: bold;
  &:hover {
    opacity: 0.7;
  }
`;

const CopyNotificationMessage = ({ message, onClose }) => {
  return (
    <CopyNotification>
      {message}
      <CloseNotificationButton onClick={onClose}>×</CloseNotificationButton>
    </CopyNotification>
  );
};

export default CopyNotificationMessage;
