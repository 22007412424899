import React from "react";
import * as Styled from "./StyleBooking.jsx";
import AnimatedGradientBackground from "../AnimatedGradient.jsx";

const Booking = () => {
  return (
    <Styled.Booking>
      <AnimatedGradientBackground />
      <Styled.ReturnButtonContainer>
        <Styled.ReturnButton onClick={() => (window.location.href = "/")}>
          На главную
        </Styled.ReturnButton>
      </Styled.ReturnButtonContainer>

      <Styled.BookingContainer>
        <Styled.Heading>Бронирование</Styled.Heading>

        <Styled.Description>
          Здесь вы можете внести информацию о бронировании.
        </Styled.Description>

        <Styled.List>
          <Styled.ListItem>Дата:</Styled.ListItem>
          <Styled.ListItem>Количество гостей:</Styled.ListItem>
        </Styled.List>
      </Styled.BookingContainer>
    </Styled.Booking>
  );
};

export default Booking;
