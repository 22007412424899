import styled from 'styled-components';

export const LoyaltyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const LoyaltyContent = styled.div`
  width: 80%;
  max-width: 800px;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
`;

export const ReturnButtonContainer = styled.div`
  margin: 20px 0px 0px 20px;
`;

export const ReturnButton = styled.button`
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;
