import styled from "styled-components";

export const Session = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  flex-direction: column;
`;

export const SessionContainer = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: rgb(16, 24, 32);
  background: linear-gradient(
    90deg,
    rgba(16, 24, 32, 1) 50%,
    rgba(40, 30, 113, 1) 120%
  );
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: white;
  font-family: Arial, sans-serif;
  z-index: 2;
`;

export const SessionsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

export const CurrentSession = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    font-size: 24px;
  }
`;

export const Button = styled.button`
  background-color: #95e0b2;
  color: black;
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #73ae8a;
  }
`;

export const ErrorText = styled.p`
  color: red;
  font-weight: bold;
  margin-top: 10px;
`;

export const SessionList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

export const SessionCard = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  h3 {
    margin: 0 0 10px 0;
    font-size: 18px;
    color: #95e0b2;
  }
`;

export const SessionInfo = styled.div`
  flex: 1;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  & button {
  margin-left: 0px;
  }
`;

export const TokenStatus = styled.p`
  color: #ddd;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
`;

export const ReturnButtonContainer = styled.div`
  position: absolute; 
  margin: 10px 10px;
  top: 20px; 
  left: 20px;
  z-index: 2;
`;

export const ReturnButton = styled.button`
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

