import * as Styled from "./StyleProfile.jsx";
import React from 'react'

const AddSocialsLinks = ({ editLinkValue, setEditLinkValue, setActiveSocial, variant  }) => {
    return (
        <Styled.EditSection variant={variant}> 
            <Styled.EditInput
                type="text"
                placeholder="Введите ссылку"
                value={editLinkValue}
                onChange={(e) => setEditLinkValue(e.target.value)}
                variant={variant}
            />
            <Styled.ApplyButton
                onClick={() => {
                    // Логика для применения изменений
                    // handleApplyLink(activeSocial, editLinkValue);
                    setActiveSocial(null);
                }}
                variant={variant}
            >
                Применить
            </Styled.ApplyButton>
        </Styled.EditSection>
    )
}

export default AddSocialsLinks