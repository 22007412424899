import React, { useState, useEffect } from "react";
import Cross from "../../img/Cross.svg";
import { ForgotPasswordApi } from "../../shared/api/SignIn.jsx";
import * as Styled from "./StyleForgotPasswordModal.jsx";

const ForgotPasswordModal = ({ isOpen, onClose }) => {
  const [input, setInput] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedOption, setSelectedOption] = useState("email"); // По умолчанию email

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (isSubmitted) validateInput(e.target.value);
  };

  const validateInput = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[0-9]{10,14}$/;
    const telegramRegex = /^@[A-Za-z0-9_]+$/;

    if (
      (selectedOption === "email" && emailRegex.test(value)) ||
      (selectedOption === "phone_number" && phoneRegex.test(value)) ||
      (selectedOption === "telegram" && telegramRegex.test(value))
    ) {
      setError("");
    } else {
      setError(
        "Введите корректный email, номер телефона или Telegram (@username)"
      );
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setInput("");
    setError("");
    setMessage("");
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    validateInput(input);

    if (error) return;

    const data = { [selectedOption]: input }; // Создаём объект с правильным полем
    const { success } = await ForgotPasswordApi(data); // Передаём объект в API

    if (success) {
      console.log("Ссылка для восстановления отправлена");
    } else {
      console.log("Ошибка при отправке ссылки");
    }
  };

  useEffect(() => {
    if (isOpen) {
      setInput("");
      setMessage("");
      setError("");
      setIsSubmitted(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Styled.ModalOverlay onClick={onClose}>
      <Styled.Modal onClick={(e) => e.stopPropagation()}>
        <Styled.CloseButton onClick={onClose}>
          <img src={Cross} alt="Close" />
        </Styled.CloseButton>
        <Styled.ModalContent>
          <Styled.ModalHeader>Восстановление пароля</Styled.ModalHeader>

          {/* Кнопки выбора метода восстановления */}
          <Styled.OptionContainer>
            <Styled.OptionButton
              $isActive={selectedOption === "email"}
              onClick={() => handleOptionClick("email")}
            >
              Email
            </Styled.OptionButton>
            <Styled.OptionButton
              $isActive={selectedOption === "phone_number"}
              onClick={() => handleOptionClick("phone_number")}
            >
              Телефон
            </Styled.OptionButton>
            <Styled.OptionButton
              $isActive={selectedOption === "telegram"}
              onClick={() => handleOptionClick("telegram")}
            >
              Telegram
            </Styled.OptionButton>
          </Styled.OptionContainer>

          <Styled.ModalInput
            type="text"
            placeholder={`Введите ${
              selectedOption === "email"
                ? "email"
                : selectedOption === "phone_number"
                ? "номер телефона"
                : "Telegram"
            }`}
            value={input}
            onChange={handleInputChange}
          />

          <Styled.ButtonMain onClick={handleSubmit}>
            Отправить ссылку
          </Styled.ButtonMain>
          {isSubmitted && error && <Styled.Message>{error}</Styled.Message>}
          {!error && message && <Styled.Message>{message}</Styled.Message>}
        </Styled.ModalContent>
      </Styled.Modal>
    </Styled.ModalOverlay>
  );
};

export default ForgotPasswordModal;
