import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const AnimatedGradientBackground = () => {
  const mountRef = useRef(null);
  const mouse = new THREE.Vector2(0, 0);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);

    if (mountRef.current) {
      mountRef.current.appendChild(renderer.domElement);
    }

    const geometry = new THREE.PlaneGeometry(2, 2);
    const uniforms = {
      u_time: { value: 0.0 },
      u_mouse: { value: mouse },
      resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
    };

    const material = new THREE.ShaderMaterial({
      uniforms,
      vertexShader: `
        void main() {
          gl_Position = vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform float u_time;
        uniform vec2 u_mouse;
        uniform vec2 resolution;

        void main() {
          vec2 st = gl_FragCoord.xy / resolution;
          float dist = distance(st, u_mouse / resolution);
          float wave = sin((dist - u_time * 0.3) * 10.0) * 0.05;

          vec3 color1 = vec3(16.0 / 255.0, 24.0 / 255.0, 32.0 / 255.0);
          vec3 color2 = vec3(40.0 / 255.0, 30.0 / 255.0, 113.0 / 255.0);
          vec3 color3 = vec3(149.0 / 255.0, 224.0 / 255.0, 178.0 / 255.0);

          vec3 color = mix(color1, color2, st.x);
          color = mix(color, color3, st.y);
          color += wave * vec3(0.5, 0.5, 0.5);

          gl_FragColor = vec4(clamp(color, 0.0, 1.0), 1.0);
        }
      `,
    });

    const plane = new THREE.Mesh(geometry, material);
    scene.add(plane);
    camera.position.z = 1;

    const animate = () => {
      requestAnimationFrame(animate);
      uniforms.u_time.value += 0.02;
      uniforms.u_mouse.value.set(mouse.x, mouse.y);

      // Обновление разрешения для плавной адаптивности
      renderer.setSize(window.innerWidth, window.innerHeight);
      uniforms.resolution.value.set(window.innerWidth, window.innerHeight);

      renderer.render(scene, camera);
    };

    const handleMouseMove = (event) => {
      mouse.x = event.clientX / window.innerWidth;
      mouse.y = 1 - event.clientY / window.innerHeight;
    };

    window.addEventListener('mousemove', handleMouseMove);
    animate();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
      }
    };
  }, []);

  return <div ref={mountRef} style={{ position: 'absolute', top: 0, left: 0, width: '100wh', height: '100vh' }}></div>;
};

export default AnimatedGradientBackground;
