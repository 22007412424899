const BASE_URL = "https://core.andreis-vibes.ru";

// Утилита для работы с куками
const setCookie = (name, value, options = {}) => {
  const optionsString = Object.entries(options)
    .map(([key, val]) => (val === true ? key : `${key}=${val}`))
    .join("; ");
  document.cookie = `${name}=${value}; ${optionsString}`;
};

const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : null;
};

// Функция для обновления данных профиля
const updateProfileData = async (updatedData) => {
  const token = getCookie("authToken");
  if (!token) throw new Error("Токен отсутствует, выполните вход");

  try {
    const response = await fetch(`${BASE_URL}/account/profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw new Error("Ошибка при обновлении профиля");
    }

    return await response.json();
  } catch (error) {
    console.error("Ошибка при обновлении профиля:", error);
    throw error;
  }
};

// Функция для изменения Telegram
const updateTelegram = async (telegram) => {
  const token = getCookie("authToken");
  if (!token) throw new Error("Токен отсутствует, выполните вход");

  try {
    const response = await fetch(`${BASE_URL}/account/profile/telegram`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ telegram }),
    });

    if (!response.ok) {
      throw new Error("Ошибка при обновлении Telegram");
    }

    return await response.json();
  } catch (error) {
    console.error("Ошибка при обновлении Telegram:", error);
    throw error;
  }
};

// Функция для изменения почты
const updateEmail = async (email) => {
  const token = getCookie("authToken");
  if (!token) throw new Error("Токен отсутствует, выполните вход");

  try {
    const response = await fetch(`${BASE_URL}/account/profile/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error("Ошибка при обновлении почты");
    }

    return await response.json();
  } catch (error) {
    console.error("Ошибка при обновлении почты:", error);
    throw error;
  }
};

// Функция для подтверждения почты
const confirmEmail = async (confirmationCode) => {
  const token = getCookie("authToken");
  if (!token) throw new Error("Токен отсутствует, выполните вход");

  try {
    const response = await fetch(`${BASE_URL}/account/profile/email/confirm`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ code: confirmationCode }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Ошибка при подтверждении почты");
    }

    return await response.json();
  } catch (error) {
    console.error("Ошибка при подтверждении почты:", error);
    throw error;
  }
};

// Функция для изменения номера телефона
const updatePhoneNumber = async (phoneNumber) => {
  const token = getCookie("authToken");
  if (!token) throw new Error("Токен отсутствует, выполните вход");

  try {
    const response = await fetch(`${BASE_URL}/account/profile/phone_number`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ phone_number: phoneNumber }),
    });

    if (!response.ok) {
      throw new Error("Ошибка при обновлении номера телефона");
    }

    return await response.json();
  } catch (error) {
    console.error("Ошибка при обновлении номера телефона:", error);
    throw error;
  }
};

// Функция для подтверждения номера телефона
const confirmPhoneNumber = async (confirmationCode) => {
  const token = getCookie("authToken");
  if (!token) throw new Error("Токен отсутствует, выполните вход");

  try {
    const response = await fetch(`${BASE_URL}/account/profile/phone_number/confirm`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ code: confirmationCode }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Ошибка при подтверждении номера телефона");
    }

    return await response.json();
  } catch (error) {
    console.error("Ошибка при подтверждении номера телефона:", error);
    throw error;
  }
};

export { 
  updateProfileData, 
  updateTelegram, 
  updateEmail, 
  confirmEmail, 
  updatePhoneNumber,
  confirmPhoneNumber 
};
