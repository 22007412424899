import React, { useEffect, useState } from "react";
import {
  getCurrentSession,
  getAllSessions,
  checkTokenStatus,
  deleteAllSessions,
  getSessionStats,
  deleteSession,
} from "../../shared/api/Sessions.jsx";
import * as Styled from "./StyleSessions.jsx";
import AnimatedGradientBackground from "../AnimatedGradient.jsx";

const Session = () => {
  const [currentSession, setCurrentSession] = useState({});
  const [allSessions, setAllSessions] = useState({});
  const [tokenStatus, setTokenStatus] = useState(null);
  const [sessionStats, setSessionStats] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentData = await getCurrentSession();
        setCurrentSession(currentData);

        const allData = await getAllSessions();
        setAllSessions(allData);

        const tokenData = await checkTokenStatus();
        if (tokenData.status) {
          setTokenStatus(tokenData.message);
        } else {
          setTokenStatus("Статус токена неизвестен");
        }
      } catch (err) {
        setError("Ошибка при загрузке данных");
      }
    };

    fetchData();
  }, []);

  const handleCheckTokenStatus = async () => {
    try {
      const data = await checkTokenStatus();
      if (data.status) {
        setTokenStatus(data.message);
      } else {
        setTokenStatus("Статус токена неизвестен");
      }
    } catch (err) {
      setError("Ошибка при проверке статуса токена: " + err.message);
    }
  };

  const handleDeleteAllSessions = async () => {
    try {
      const response = await deleteAllSessions();
      if (response.success) {
        setAllSessions({});
        setError(null);
      } else {
        setError("Ошибка при удалении всех сеансов");
      }
    } catch (err) {
      setError("Ошибка при удалении всех сеансов: " + err.message);
    }
  };
  
  
  const handleDeleteSession = async (sessionId) => {
    try {
      const response = await deleteSession(sessionId);
      if (response.success) {
        setAllSessions(prevSessions => 
          prevSessions.filter(session => session.session_id !== sessionId)
        );
        
      } else {
        setError("Ошибка при удалении сессии: " + response.message);
      }
    } catch (err) {
      setError("Ошибка при удалении сессии: " + err.message);
    }
  };
  
  const handleGetSessionStats = async (sessionId) => {
    try {
      const data = await getSessionStats(sessionId);
      setSessionStats(data);
    } catch (err) {
      setError("Ошибка при получении статистики по сеансу: " + err.message);
    }
  };

  return (
    <Styled.Session>
      <AnimatedGradientBackground />
      <Styled.ReturnButtonContainer>
        <Styled.ReturnButton onClick={() => (window.location.href = "/")}>
          На главную
        </Styled.ReturnButton>
      </Styled.ReturnButtonContainer>

      <Styled.SessionContainer>
        <Styled.Header>
          <h1>Управление сеансами</h1>
          <Styled.Button onClick={handleCheckTokenStatus}>
            Проверить статус токена
          </Styled.Button>
          <Styled.Button onClick={handleDeleteAllSessions}>
            Удалить все сеансы
          </Styled.Button>
        </Styled.Header>

        <Styled.CurrentSession>
          <h2>Текущий сеанс:</h2>
          {Object.keys(currentSession).length > 0 ? (
            Object.entries(currentSession).map(([key, value]) => (
              <div key={key}>{`${key}: ${value}`}</div>
            ))
          ) : (
            <p>Текущий сеанс не найден</p>
          )}
        </Styled.CurrentSession>

        <h2>Все сеансы:</h2>
        <Styled.SessionsList>
          {Object.keys(allSessions).length > 0 ? (
           Object.entries(allSessions).map(([session_id, session]) => (
            <Styled.SessionCard key={session_id}>
              <Styled.SessionInfo>
                <h3>ID: {session.session_id}</h3>
                  <p>Создана: {session.created_at}</p>
                  <p>IP: {session.ip}</p>
                  <p>Последний доступ: {session.last_access}</p>
                  <p>Платформа: {session.platform}</p>

                  <Styled.ButtonGroup>
                    <Styled.Button
                      onClick={() => handleDeleteSession(session.session_id)}
                    >
                      Удалить
                    </Styled.Button>
                    <Styled.Button
                      onClick={() => handleGetSessionStats(session.session_id)}
                    >
                      Получить статистику
                    </Styled.Button>
                  </Styled.ButtonGroup>
                </Styled.SessionInfo>
              </Styled.SessionCard>
            ))
          ) : (
            <Styled.ErrorText>Сеансы не найдены</Styled.ErrorText>
          )}
        </Styled.SessionsList>

        {error && <Styled.ErrorText>{error}</Styled.ErrorText>}
        {tokenStatus && (
          <Styled.TokenStatus>Статус токена: {tokenStatus}</Styled.TokenStatus>
        )}
        {sessionStats && (
          <Styled.TokenStatus>
            Статистика сеанса: {JSON.stringify(sessionStats)}
          </Styled.TokenStatus>
        )}
      </Styled.SessionContainer>
    </Styled.Session>
  );
};

export default Session;
