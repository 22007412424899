import React, { useEffect, useState } from "react";
import * as Styled from "./StyleUserMenu.jsx";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { fetchProfileData, LogoutApi } from "../../../shared/api/SignIn.jsx";

const UserMenu = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfileData()
      .then((data) => {
        setUserData(data);
      })
      .catch(() => {
        console.error("Ошибка при загрузке данных пользователя");
      });
  }, []);

  const handleLogout = async () => {
    try {
      await LogoutApi();
      navigate("/login");
    } catch (error) {
       console.error("Ошибка при выходе из аккаунта");
    }
  };

  return (
    <Styled.Container>
      <Styled.Top>
      <Styled.Info>
        <Styled.UserName>{userData?.full_name || "Имя не указано"}</Styled.UserName>
        <Styled.UserRole>{userData?.role || "Роль не указана"}</Styled.UserRole>
      </Styled.Info>
      <Styled.ProfileCircle
        style={{
          backgroundImage: userData?.avatar
            ? `url(${userData.avatar})`
            : "none",
        }}
      />
      </Styled.Top>
      
      <Styled.Menu>
        <Styled.Button as={Link} to="/profile">Личный кабинет</Styled.Button>
        <Styled.ButtonExit onClick={handleLogout}>Выйти</Styled.ButtonExit>
      </Styled.Menu>
    </Styled.Container>
  );
};

export default UserMenu;
