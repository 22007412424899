import React from "react";
import styled from "styled-components";
import { sizes } from "../CommonComponents/Sizes.jsx";

const StyledInput = styled.input`
  width: 100%;
  height: ${sizes.inputHeight};
  border-bottom: 3px solid rgba(149, 224, 178, 1);
  border-radius: 0.25rem;
  background-color: rgba(16, 24, 32, 1);
  padding: 0.69rem 0 0.69rem 1.5rem;
  font-size: 32px;

  &::placeholder {
    text-align: left;
    color: rgba(140, 140, 140, 1);
    font-size: 32px;
  }
`;

const CustomInput = ({ type, placeholder, value, onChange }) => {
  return (
    <StyledInput
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default CustomInput;
