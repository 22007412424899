import styled, { css } from 'styled-components';
import { spacing, fontSizes } from "../CommonComponents/Sizes.jsx";

export const Profile = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const MainContent = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 3.25rem;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 3.25rem;
    left: 3.75rem;
    right: 3.75rem;
    bottom: 2.75rem;
    height: calc(100% - 6rem);
    background: rgba(40, 30, 113, 0.8);
    border-radius: 6px;
    z-index: -1;
    max-height: 100%;
  }

  @media (max-width: 1440px) {
    gap: 3rem;
    &::before {
      top: 3rem;
      left: 3.5rem;
      right: 3.5rem;
      bottom: 2.5rem;
      height: calc(100% - 8rem);
    }
  }

  @media (max-width: 1024px) {
    gap: 2rem;
    &::before {
      top: 2.5rem;
      left: 2.25rem;
      right: 2.25rem;
      bottom: 2rem;
    }
  }
  }
`;

export const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: none;
  gap: 1rem;
  margin: 3.25rem 3.75rem 2.75rem;
  height: calc(51.4375rem - 6rem);
  z-index: 4;

  @media (max-width: 1440px) {
    margin: 3rem 3.5rem 2.5rem;
  }

  @media (max-width: 1024px) {
    margin: 2.5rem 1.25rem 2rem;
  }
`;

export const SubTitleContainer = styled.div`
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  padding: 11px 58px;
  background: rgba(16, 24, 32, 1);
  border-radius: 4px;
  display: inline-block;

  @media (max-width: 1440px) {
    padding: 9px 48px;
  }
  @media (max-width: 1024px) {
    margin-left: 1rem;
  }
`;

export const SubTitle = styled.h1`
  font-size: ${fontSizes.heading};
  font-weight: 500;
  text-transform: uppercase;
  color: white;

  @media (max-width: 1440px) {
    font-size: ${fontSizes.heading};
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.subheading};
  }
`;

export const Info = styled.div`
  margin-top: 6rem;
  padding: 0 64px;

  @media (max-width: 1440px) {
    margin-top: 6rem;
    padding: 0 50px;
  }

  @media (max-width: 1024px) {
    margin-top: 5rem;
    padding: 0 40px;
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProfileFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background: ${(props) =>
    props.isEditing ? "transparent" : "rgba(16, 24, 32, 1)"};
  align-items: center;
  position: relative;
 height: ${(props) =>
    props.isLarge
      ? props.isEditing
        ? "6.3rem"
        : "10rem"
      : "3.375rem"};
  width: 100%;

  @media (max-width: 1440px) {
    height: ${(props) =>
    props.isLarge
      ? props.isEditing
        ? "6.525rem"
        : "9rem"
      : "3.038rem"};
  }

  @media (max-width: 1024px) {
    height: ${(props) =>
    props.isLarge
      ? props.isEditing
        ? "5.5rem"
        : "7rem"
      : "2.362rem"};
  }
`;

export const FieldLabel = styled.div`
  display: flex;
  font-size: ${fontSizes.subheading};
  font-weight: 500;
  background: rgba(40, 30, 113, 1);
  height: 3.375rem;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 13px 35px;
  border-radius: 4px;

  ${(props) =>
    props.isCareerGoals &&
    `
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    border-radius: 4px;
  `}

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
    padding: 11px 20px;
    height: 3.1rem;
  }

  @media (max-width: 1024px) {
    font-size: 16px;
    padding: 9px 14px;
    height: 2.362rem;
  }
`;

export const FieldValue = styled.div`
  font-size: ${fontSizes.subheading};
  font-weight: 300;
  margin-left: ${spacing.large};

  ${(props) =>
    props.isLarge &&
    `
    height: 161px;
    display: flex;
    align-items: center;
    padding: 1rem;
    overflow-y: auto;
  `}

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
    margin-left: ${spacing.medium};

    ${(props) =>
    props.isLarge &&
    `
      height: calc(161px * 0.8);
      padding: calc(1rem * 0.8);
    `}
  }

  @media (max-width: 1024px) {
    font-size: 16px;
    margin-left: ${spacing.xmedium};

    ${(props) =>
    props.isLarge &&
    `
      height: calc(161px * 0.6);
      padding: calc(1rem * 0.6);
    `}
  }
`;

export const ErrorText = styled.p`
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
`;

export const SocialIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 40px;
  &:hover {
    opacity: 0.8;
  }
  object-fit: contain; /* Гарантирует, что иконки не выходят за пределы контейнера */
  
  @media (max-width: 1440px) {
     width: 30px;
     height: 30px;
     margin-right: 25px;
  }

  @media (max-width: 1024px) {
     width: 30px;
     height: 30px;
     margin-right: 25px;
  }
`;

export const Photo = styled.img`
  width: 20rem;
  height: 25.625rem;
  border-radius: 6px;
  object-fit: cover;
  z-index: 3;

  @media (max-width: 1440px) {
    width: 17rem; 
    height: 22rem;
  }

  @media (max-width: 1024px) {
    width: 12rem; 
    height: 17rem;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20rem;
  max-width: none;

  @media (max-width: 1440px) {
    width: 17rem; 
  }

  @media (max-width: 1024px) {
    width: 12rem;
  }
`;

export const UserInfoContainer = styled.div`
  width: 20rem;
  height: 6.6rem;
  margin-top: -0.2rem;
  z-index: 2;
  background: rgba(16, 24, 32, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px -2px 2px 0px rgba(149, 224, 178, 1) inset;

  @media (max-width: 1440px) {
    width: 17rem; 
    height: 5rem;
    padding: 0rem 1.5rem; 
  }

  @media (max-width: 1024px) {
    width: 12rem;
    height: 4.5rem;
    padding: 0rem 0.8rem;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 17px;

  @media (max-width: 1440px) {
    padding: 11px 0;
  }

  @media (max-width: 1024px) {
    padding: 8px 0;
  }
`;

export const FullName = styled.h2`
  font-size: ${fontSizes.subheading};
  color: #fff;
  font-weight: 300;
  margin: 0;

  @media (max-width: 1440px) {
    font-size: 18px;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const Position = styled.p`
  font-size: ${fontSizes.subheading};
  color: #95e0b2;
  font-weight: 300;
  margin-top: 5px;

  @media (max-width: 1440px) {
    font-size: 18px;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const GenderIcon = styled.img`
  width: 26px;
  height: 26px;
  margin-left: auto;

  @media (max-width: 1440px) {
    width: 22px;
    height: 22px;
  }

  @media (max-width: 1024px) {
    width: 16px;
    height: 18px;
  }
`;

export const SocialMediaFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: rgba(73, 44, 255, 1);
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  align-items: center;
  margin-bottom: ${(props) => (props.activeSocial ? '0' : '1rem')};
  height: 5.125rem;
  border-radius: 3px; 

  @media (max-width: 1024px) {
    height: 4rem;
  }
`;

export const SocialMediaTitle = styled.h3`
  display: flex;
  font-size: ${fontSizes.subheading};
  font-weight: 500;
  background: rgba(16, 24, 32, 1);
  height: 5.125rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 309px;
  border-radius: 3px;

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
    width: 280px;
  }

  @media (max-width: 1024px) {
    height: 4rem; 
    font-size: 16px;
    width: 250px;
  }
`;

export const SocialMediaLinks = styled.div`
  display: flex;
  gap: 1rem;
  overflow-x: auto; 
  overflow-y: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin-left: 30px;
  padding: 10px 0;
    
  &::-webkit-scrollbar {
    height: 8px; 
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(149, 224, 178, 1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(40, 30, 113, 0.8);
    border-radius: 4px;
  }

  @media (max-width: 1440px) {
    padding-bottom: 10px;
    margin-left: 15px;
  }

  @media (max-width: 1024px) {
    padding-bottom: 8px;
    margin-left: 15px;
    &::-webkit-scrollbar {
    height: 6px; 
  }

  }
`;

export const RightColumn = styled.div`
  position: relative;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    margin-bottom: ${(props) => (props.isEditing ? "1.4375rem" : "1rem")};
  }

  & > :nth-last-child(3) {
    margin-bottom: ${(props) => (props.activeSocial ? "0" : "1.4375rem")};
  }

  @media (max-width: 1440px) {
    margin-left: 2rem;
    margin-right: 2rem;

    & > * {
    margin-bottom: 0.6rem;
    }
    & > :nth-last-child(3) {
    margin-bottom: ${(props) => (props.activeSocial ? "0" : "0.6rem")};
  }

  @media (max-width: 1024px) {
    margin-left: 1rem;
    margin-right: 1rem;
    & > * {
    margin-bottom: ${(props) => (props.isEditing ? "1rem" : "0.6rem")};
    }
    & > :nth-last-child(3) {
    margin-bottom: ${(props) => ((props.activeSocial && props.isEditing) ? "0" : "1rem")};
    }
`;

export const StatusIcon = styled.img`
  position: absolute;
  right: 13px;
  height: 24px;
  width: 18px;
  margin-top: 2px;

  @media (max-width: 1440px) {
    height: 21px;
    width: 15px;
  }

   @media (max-width: 1024px) {
    height: 18px;
    width: 12px;
  }
`;

export const CopyIcon = styled.img`
  position: absolute;
  right: 45px;
  height: 21px;
  width: 24px;
  margin-top: 3px;
  cursor: pointer;

  @media (max-width: 1440px) {
    height: 18px;
    width: 21px;
    right: 40px;
  }

   @media (max-width: 1024px) {
    height: 16px;
    width: 16px;
    right: 35px;
  }
`;

export const EditProfileButton = styled.button`
  position: absolute;
  right: 0;
  bottom: ${(props) =>
    props.activeSocial
      ? "-4rem"
      : props.isEditing
        ? "-1rem"
        : "-5rem"};
  width: 20rem;
  height: 3.125rem;
  padding: 13px;
  font-size: ${fontSizes.button};
  background: rgba(149, 224, 178, 1);
  color: black;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;

  @media (max-width: 1440px) {
    width: 17rem;
    padding: 10px;
    height: 2.5rem;
    font-size: 16px;
     bottom: ${(props) =>
    props.activeSocial
      ? "-3rem"
      : props.isEditing
        ? "0rem"
        : "-4rem"};
  }

  @media (max-width: 1024px) {
    width: 15rem;
    padding: 10px;
    height: 2.5rem;
    font-size: 14px;
    bottom: ${(props) =>
    props.activeSocial
      ? "-3rem"
      : props.isEditing
        ? "-3rem"
        : "-4rem"};
  }

  &:hover {
    background-color: #3f51b5;
  }

  &:active {
    background-color: rgba(40, 30, 113, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform: translateY(2px);
    color: white;
    animation: buttonActive 300ms ease-in-out forwards;
  }
`;

export const UploadPhotoButton = styled.button`
  width: 20rem;
  height: 3.125rem;
  padding: 0;
  margin-top: 2rem;
  font-size: ${fontSizes.button};
  background: rgba(149, 224, 178, 1);
  color: black;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;

  @media (max-width: 1440px) {
    width: 17rem;
    height: 3.125rem;
    font-size: 16px;
    margin-top: 1rem;
    padding: 0;
  }

  @media (max-width: 1024px) {
    width: 12rem;
    height: 3.125rem;
    font-size: 14px;
    margin-top: 1rem;
    padding: 0;
  }

  &:hover {
    background-color: #3f51b5;
  }

  &:active {
  background-color: rgba(40, 30, 113, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transform: translateY(2px);
  color: white;
  animation: buttonActive 300ms ease-in-out forwards;
`;

export const NotificationsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap:  ${(props) =>
    props.isEditing ? "0rem" : "1rem"};
  max-width: 20rem;

  @media (max-width: 1440px) {
    max-width: 17rem;
  }

  @media (max-width: 1024px) {
    max-width: 12rem;
  }
`;

export const Input = styled.input`
  background: rgba(16, 24, 32, 1);
  border-bottom: 4px solid rgba(149, 224, 178, 1);
  padding: 0 40px;
  font-size: ${fontSizes.button};
  width: 100%;
  color: rgba(140, 140, 140, 1);
  border-radius: 5px;
  height: ${(props) => (props.isLarge ? "7.25rem" : "3.375rem")};

  &:focus {
    border: 3px solid rgba(149, 224, 178, 1);
    outline: none;
  }

  &::placeholder {
    color: rgba(140, 140, 140, 1);
  }

  @media (max-width: 1440px) {
    height: ${(props) => (props.isLarge ? "6rem" : "2.75rem")};
    padding: 0 30px;
    font-size: 18px;
  }

  @media (max-width: 1024px) {
    height: ${(props) => (props.isLarge ? "6rem" : "2.75rem")};
    padding: 0 30px;
    font-size: 16px;
  }
`;

//isEditing Панель добавления соцсетей

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 14px;
  height: 3.375rem;
  background: rgba(16, 24, 32, 1);
  border-bottom: 3px solid rgba(149, 224, 178, 1);
  border-radius: 4px;
  font-size: ${fontSizes.inputText};
  color: rgba(140, 140, 140, 1);

  @media (max-width: 1440px) {
    height: 2.75rem; 
    font-size: 18px;
  }

  @media (max-width: 1024px) {
    height: 2.75rem; 
    font-size: 14px;
    padding: 12px;
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  right: 14px;
  cursor: pointer;

  @media (max-width: 1440px) {
    width: 22px;
    height: 22px;
  }

  @media (max-width: 1024px) {
    width: 16px;
    height: 16px;
    right: 12px;
  }
`;

export const SocialsList = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  background: rgba(73, 44, 255, 1);
  gap: 16px;
  padding: 22px 17px;
  max-height: 19rem;
  width: 19.6875rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 27px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(40, 30, 113, 1);
    height: 263px;
    border-radius: 129px;
    margin: 22px 8px 22px 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(149, 224, 178, 1);
    border-radius: 20px;
    height: 50px;
  }

  @media (max-width: 1440px) {
    max-width: 17rem;
    gap: 14px;
    padding: 18px 14px;
    max-height: 18.4rem;
  }

  @media (max-width: 1024px) {
    max-width: 12rem;
    gap: 12px;
    padding: 18px 14px;
    max-height: 16.4rem;

    &::-webkit-scrollbar {
    width: 17px;
    }

    &::-webkit-scrollbar-track {
    margin: 22px 8px 22px 0;
    }
  }
`;

export const SocialItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  @media (max-width: 1440px) {
   gap: 2px;
  }

  @media (max-width: 1024px) {
   gap: 0px;
  }
`;

export const SocialName = styled.div`
  flex: 1;
  color: rgba(16, 24, 32, 1);
  font-size: ${fontSizes.inputText};
  font-weight: 600;
  margin-left: 8px;
  cursor: pointer;

  @media (max-width: 1440px) {
    margin-left: 6px;
    font-size: 18px;
  }

  @media (max-width: 1024px) {
    margin-left: 4px;
    font-size: 16px;
  }
`;

export const EditSection = styled.div`
  position: relative;  
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ variant }) =>
    variant === "alternative" &&
    css`
      flex-direction: row;
  
      @media (max-width: 1440px) {
        width: 40rem;
      }
  
      @media (max-width: 1024px) {
        width: 30rem;
      }
    `}
  
     ${({ variant }) =>
    variant === "regular" &&
    css`
        @media (max-width: 1440px) {
          width: 17rem;
        }

        @media (max-width: 1024px) {
          width: 12rem;
        }
    `}
`;

export const EditInput = styled.input`
  width: 100%;
  padding: 14px;
  height: 3.3125rem;
  background: rgba(16, 24, 32, 1);
  border-bottom: 3px solid rgba(149, 224, 178, 1);
  border-radius: 4px;
  font-size: ${fontSizes.inputText};
  color: rgba(140, 140, 140, 1);

  @media (max-width: 1440px) {
    height: 2.75rem; 
    font-size: 18px;
  }

  @media (max-width: 1024px) {
    height: 2.75rem; 
    font-size: 16px;
    padding: 12px;
  }
`;

export const ApplyButton = styled.button`
  position: absolute;
  background: rgba(149, 224, 178, 1);
  font-size: ${fontSizes.inputText};
  color: black;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
  padding: 14px;
  
  ${({ variant }) =>
    variant === "alternative" &&
    css`
    top: 0rem;
    right: 0rem;

    @media (max-width: 1440px) {
      font-size: 16px;
      padding: 12px;
    }

    @media (max-width: 1024px) {
      font-size: 14px;
      padding: 14px;
    }
  `}

  ${({ variant }) =>
    variant === "regular" &&
    css`
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 2560px) {
      top: 6rem;
    }

    @media (max-width: 1440px) {
    font-size: 16px;
    padding: 14px;
    top: 5rem;
    }

    @media (max-width: 1024px) {
      font-size: 14px;
      padding: 12px;
      top: 5rem;
    }
  `}

  &:hover {
    background-color: #3f51b5;
  }

  &:active {
  background-color: rgba(40, 30, 113, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
  animation: buttonActive 300ms ease-in-out forwards;
`;
