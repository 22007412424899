import React from "react";
import * as Styled from "./StylesProfileHeader.jsx";
import { Logo, ReturnButton } from "../../../img/icons.js";

const ProfileHeader = () => (
  <Styled.Header>
    <Styled.HeaderBackground />
    <Styled.HeaderContent>
      <Styled.Logo src={Logo} alt="Логотип" onClick={() => (window.location.href = "/")} />
      <Styled.ReturnButton src={ReturnButton} alt="Назад" onClick={() => (window.location.href = "/")} />
      <Styled.Title>Личный кабинет</Styled.Title>
      <Styled.HeaderButtons>
        <Styled.HeaderButton>Управление сменами</Styled.HeaderButton>
        <Styled.HeaderButton>Настройки</Styled.HeaderButton>
        <Styled.HeaderButton>Безопасность</Styled.HeaderButton>
      </Styled.HeaderButtons>
    </Styled.HeaderContent>
  </Styled.Header>
);

export default ProfileHeader;
