import styled from "styled-components";

export const ButtonMain = styled.button`
  background-color: #95e0b2;
  margin-top: 10px;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #73ae8a;
  }
`;

export const ModalOverlay = styled.div`
  width: auto;
  height: auto;
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  // background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

export const Modal = styled.div`
  background-color: #ffffff;
  width: 500px;
  height: 300px;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalHeader = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

export const ModalInput = styled.input`
  width: 300px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
  color: black;
  
  &:focus {
    border-color: #95e0b2;
    outline: none;
  }
`;

export const Message = styled.p`
  font-size: 14px;
  color: red;
  margin-top: 15px;
`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 20px;
`;

export const OptionButton = styled.button`
  background-color: ${({ $isActive }) => ($isActive ? '#95e0b2' : '#f5f5f5')};
  color: ${({ $isActive }) => ($isActive ? 'black' : '#333')};
  border: 1px solid ${({ $isActive }) => ($isActive ? '#73ae8a' : '#ccc')};
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${({ $isActive }) => ($isActive ? '#95e0b2' : '#e0e0e0')};
    border-color: ${({ $isActive }) => ($isActive ? '#73ae8a' : '#73ae8a')};
  }

  &:active {
    background-color: #95e0b2;
    color: black;
    border-color: #73ae8a;
  }
`;
