import React from "react";
import * as Styled from "./StyleMainPage.jsx";
import { Link } from "react-router-dom";
import logo from "../../img/Logo1.svg";
import AnimatedGradientBackground from "../AnimatedGradient.jsx";
import UserMenu from "../CommonComponents/UserMenu/UserMenu.jsx";

const MainPage = () => {
  return (
    <Styled.MainPageContainer>
      <AnimatedGradientBackground />
      <Styled.VerticalStripe />
      <UserMenu />

      <Styled.GridContainer>
        <Styled.Logo src={logo} alt="Логотип" />
        <Styled.Headline>АДМИН-ПАНЕЛЬ</Styled.Headline>
        <Styled.LinkList>
          <Styled.ButtonMain as={Link} to="/login">
            Вход
          </Styled.ButtonMain>
          <Styled.ButtonMain as={Link} to="/booking">
            Бронирование
          </Styled.ButtonMain>
          <Styled.ButtonMain as={Link} to="/loyalty">
            Лояльность
          </Styled.ButtonMain>
          <Styled.ButtonMain as={Link} to="/sessions">
            Сессии
          </Styled.ButtonMain>
        </Styled.LinkList>
      </Styled.GridContainer>
    </Styled.MainPageContainer>
  );
};

export default MainPage;
