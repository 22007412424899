import React from 'react';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
  color: white;
  text-align: center;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 60px;
  margin: 0;
  font-weight: bold;
`;

const Message = styled.p`
  font-size: 24px;
  margin: 10px 0;
`;

const HomeLink = styled.a`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffffff;
  color: #333;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #f0f0f0;
    color: #000;
  }
`;

const NotFound = () => {
    return (
      <NotFoundContainer>
        <Title>404 - Страница не найдена</Title>
        <Message>
          Страница, которую вы ищете, могла быть удалена, её название изменилось или она временно недоступна
        </Message>
        <HomeLink href="/">Вернуться на главную</HomeLink>
      </NotFoundContainer>
    );
  };  
  
  export { NotFound };