import styled from "styled-components";
import { fontSizes } from "../../CommonComponents/Sizes.jsx";

export const Header = styled.header`
  display: grid;
  align-items: center;
  width: 100%;
  height: 160px;
  padding: 0 60px;
  border-radius: 0px 0px 4px 4px;
  position: relative;

  @media (max-width: 1440px) {
    height: 140px;
    padding: 0 50px;
  }

  @media (max-width: 1024px) {
    height: 100px;
    padding: 0 40px;
  }
`;

export const HeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(40, 30, 113, 1);
  opacity: 50%;
  z-index: 1;
  border-radius: 0px 0px 4px 4px;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
`;

export const HeaderButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 2.5rem;
  height: 50px;
  margin-left: auto;

  @media (max-width: 1440px) {
    gap: 1.5rem;
    height: 40px;
  }

  @media (max-width: 1024px) {
    gap: 1rem;
    height: 35px;
  }
`;

export const HeaderButton = styled.button`
  padding: 0px 15px;
  font-size: ${fontSizes.button};
  font-weight: 600;
  background-color: #95e0b2;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
  width: 166px;
  max-height: 50px;

  &:hover {
    background: #0056b3;
  }

  @media (max-width: 1440px) {
    width: 150px;
    padding: 0px 12px;
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    padding: 0px 10px;
    font-size: 14px;
    width: 120px;
    max-height: 40px;
  }
`;

export const Logo = styled.img`
  height: 50px;
  cursor: pointer;

  @media (max-width: 1440px) {
    height: 45px;
  }

  @media (max-width: 1024px) {
    height: 35px;
  }
`;

export const Title = styled.h1`
  font-size: ${fontSizes.heading};
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  margin-left: 15px;

  @media (max-width: 1440px) {
    font-size: ${fontSizes.heading};
    margin-left: 12px;
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.subheading};
    margin-left: 10px;
  }
`;

export const ReturnButton = styled.img`
  cursor: pointer;
  margin-left: 85px;
  height: 60px;

  @media (max-width: 1440px) {
    margin-left: 70px;
    height: 50px;
  }

  @media (max-width: 1024px) {
    margin-left: 40px;
    height: 35px;
  }
`;
