import styled from "styled-components";
import { fontSizes } from "../Sizes.jsx";

export const Container = styled.div`
  position: absolute;
  top: 1.875rem;
  right: 3.75rem;
  height: auto;
  display: flex;
  align-items: center;
  gap: 1.8125rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    right: 1rem;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1.625rem;
  background: rgba(16, 24, 32, 1);
  border-radius: 44px;
  box-shadow: 0px -4px 4px 0px rgba(149, 224, 178, 1) inset;
  width: auto;
  z-index: 3;
  height: 6.25rem;

  @media (max-width: 1024px) {
    padding-left: 1.625rem;
    height: 5rem;
    width: 100%;
    gap: 0.5rem;
  }
`;

export const Info = styled.div`
  cursor: pointer;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const UserName = styled.span`
  font-size: ${fontSizes.subheading};
  font-weight: 600;
  color: white;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;

export const UserRole = styled.span`
  font-size: ${fontSizes.button};
  margin-top: 0.375rem;
  font-weight: 600;
  color: #95e0b2;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;

export const ProfileCircle = styled.div`
  z-index: 3;
  width: 6.25rem;
  height: 6.25rem;
  background-size: cover;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  box-shadow: 0px -4px 4px 0px rgba(149, 224, 178, 1) inset;
  transform: translate(0.25rem, -0.05rem);

  @media (max-width: 1024px) {
    width: 5rem;
    height: 5rem;
  }
`;

export const Menu = styled.div`
  display: flex;
  z-index: 2;
  align-items: center;
  width: 100%;
  height: 16rem;
  flex-direction: column;
  position: absolute;
  top: 2.5rem;
  right: 0;
  background: rgba(16, 24, 32, 1);
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  opacity: 0;
  visibility: hidden;

  ${Container}:hover & {
    opacity: 80%;
    visibility: visible;
  }

  @media (max-width: 1024px) {
    height: 11.5rem;
  }
`;

export const Button = styled.a`
  width: 16.875rem;
  height: 3.25rem;
  background-color: #95e0b2;
  color: black;
  padding: 0.875rem;
  font-size: ${fontSizes.button};
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, font-size 0.3s ease;
  margin-top: 90px;

  &:hover {
    background-color: #73ae8a;
  }

  &:active {
    background-color: rgba(40, 30, 113, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform: translateY(2px);
    color: white;
    animation: buttonActive 300ms ease-in-out forwards;
  }

  @media (max-width: 1024px) {
    width: 14rem;
    height: 2.5rem;
    font-size: 18px;
    margin-top: 60px;
  }
`;

export const ButtonExit = styled(Button)`
  margin-top: 30px;
  background-color: rgba(40, 30, 113, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transform: translateY(2px);
  color: white;
  animation: buttonActive 300ms ease-in-out forwards;

  @media (max-width: 1024px) {
    margin-top: 15px;
  }
`;
