import styled from 'styled-components';

export const Booking = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`

export const BookingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  
  text-align: center; 
  position: absolute; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 2; 
`;

export const Heading = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  margin-bottom: 20px;
  text-align: center;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 8px;
`;

export const ReturnButtonContainer = styled.div`
  margin: 20px 0px 0px 20px;
`;

export const ReturnButton = styled.button`
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;
