import React from "react";
import styled from "styled-components";
import { fontSizes } from "../CommonComponents/Sizes.jsx";

const PanelContainer = styled.div`
  background: rgba(16, 24, 32, 1);
  padding: 0;
  border-radius: 4px;
  width: 19.1875rem;

  @media (max-width: 1440px) {
    width: 17rem;
  }

  @media (max-width: 1024px) {
    width: 12rem;
  }
`;

const PanelHeader = styled.div`
  padding: 22px 44px;
  text-align: center;
  font-size: ${fontSizes.subheading};
  font-weight: bold;
  border-bottom: 2px solid #95E0B2;

  @media (max-width: 1440px) {
    padding: 20px 35px;
    font-size: ${fontSizes.button};
  }

  @media (max-width: 1024px) {
    padding: 15px 30px;
    font-size: 18px;
  }
`;

const SettingItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 51px 22px 0 22px;
  font-size: ${fontSizes.subheading};
  font-weight: 300;
  line-height: 28.13px;
  color: #fff;

  label {
    max-width: 150px;
  }

  &:first-child {
    margin-top: -4px; 
  }

  &:last-child {
    margin-bottom: 14px; 
    padding: 51px 22px;
  }

  @media (max-width: 1440px) {
    padding: 35px 18px 0 18px;
    font-size: ${fontSizes.button};

    &:last-child {
      margin-bottom: 10px; 
      padding: 35px 18px;
    }
  }

  @media (max-width: 1024px) {
    padding: 25px 14px 0 14px;
    font-size: 16px;
    line-height: 120%;

    &:last-child {
      margin-bottom: 10px; 
      padding: 25px 14px;
    }
  }
`;

const Switch = styled.input`
  width: 5rem;
  height: 2.296rem;
  background: rgba(73, 44, 255, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 129px;
  appearance: none;
  cursor: pointer;
  position: relative;
  outline: none;

  &:checked {
    background: rgba(40, 30, 113, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &:before {
    content: "";
    width: 26.21px;
    height: 26.21px;
    background: rgba(149, 224, 178, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    position: absolute;
    top: 15%;
    left: 8px;
    transition: transform 0.3s;
  }

  &:checked:before {
    transform: translateX(2.5rem);
    background: rgba(73, 44, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 1440px) {
    width: 4.25rem;
    height: 2rem;

    &:before {
      top: 9%;
      left: 6px;
    }
  }

  @media (max-width: 1024px) {
    width: 2.4rem;
    height: 1.2rem;

    &:before {
      top: 14%;
      left: 4px;
      width: 14.21px;
      height: 14.21px;
    }

    &:checked:before {
    transform: translateX(1rem);
  }
  }
`;

const NotificationsPanel = () => {
  const settings = [
    { label: "Включить все", id: "all" },
    { label: "SMS", id: "sms" },
    { label: "E-mail", id: "email" },
    { label: "Телеграм", id: "telegram" },
  ];

  return (
    <PanelContainer>
      <PanelHeader>Уведомления</PanelHeader>
      {settings.map((setting) => (
        <SettingItem key={setting.id}>
          <label htmlFor={setting.id}>{setting.label}</label>
          <Switch type="checkbox" id={setting.id} />
        </SettingItem>
      ))}
    </PanelContainer>
  );
};

export default NotificationsPanel;
