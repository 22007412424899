import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { checkTokenStatus } from '../../shared/api/Sessions.jsx';

const CheckToken = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await checkTokenStatus();
        setIsAuthenticated(response.status);
      } catch (err) {
        console.error('Ошибка проверки токена:', err.message);
        setIsAuthenticated(false);
      }
    };

    verifyToken();
  }, []);

  if (isAuthenticated === null) {
    return null; 
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default CheckToken;
